import React, { useEffect, useState } from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Confetti from 'react-dom-confetti';
import {getGuest} from "../firebase";

function Yes() {
    const config = {
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: 100,
        dragFriction: 0.25,
        duration: 6000,
        stagger: 3,
        width: "10px",
        height: "10px",
        perspective: "500px",
        colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
    };

    const [confettiActive, setConfettiActive] = useState(false);
    const navigate = useNavigate();
    const { guestId } = useParams();
    const [guestData, setGuestData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const confettiTimeout = setTimeout(() => {
            setConfettiActive(true);
        }, 500);

        return () => clearTimeout(confettiTimeout);
    }, []);

    useEffect(() => {
        console.log(guestId);
        getGuest(guestId)
            .then((snapshot) => {
                if (snapshot.exists()) {
                    setGuestData(snapshot.val());
                } else {
                    console.log('Guest not found');
                }
            })
            .catch((error) => {
                console.error('Error fetching guest:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [guestId]);

    useEffect(() => {
        if (!loading && guestData && !guestData.hasResponse) {
            console.log("hello")
            navigate('/rsvp');
        }
        if (!loading && guestData && guestData.hasResponse) {
            if (!guestData.rsvp) {
                navigate(`/rsvp/${guestId}/no`);
            }
        }
    }, [guestData, guestId, loading, navigate]);

    return (
        <div className="mt-32 md:mt-64 flex flex-col items-center">
            <Confetti
                active={confettiActive}
                config={config}
                onAnimationEnd={() => {
                    document.querySelector('.confetti-wrapper').remove();
                }}
            />
            <p className="text-4xl font-bold text-center mb-4">
                Thank you for RSVPing!
            </p>
            <p className="text-xl text-center mb-4">
                We can't wait to celebrate with you!
            </p>
            <Link
                to="/"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full text-xl focus:outline-none focus:shadow-outline"
            >
                Return to Home
            </Link>
        </div>
    );
}

export default Yes;


