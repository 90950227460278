// function Footer() {
//     // Calculate the number of days remaining until June 1, 2024
//     const countdownDate = new Date('2024-06-01');
//     const today = new Date();
//     const timeDiff = countdownDate.getTime() - today.getTime();
//     const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));
//
//     return (
//         <footer className="footer">
//             {/*<div className="countdown">{daysRemaining} days to go!</div>*/}
//             <nav className="footer-nav">
//                 {/*<ul>*/}
//                 {/*    /!*<li><Link to="/">Home</Link></li>*!/*/}
//                 {/*    /!*<li><Link to="/about">About</Link></li>*!/*/}
//                 {/*    /!*<li><Link to="/gallery">Story</Link></li>*!/*/}
//                 {/*    /!*<li><Link to="/events">Events</Link></li>*!/*/}
//                 {/*    /!*<li><Link to="/contact">Contact</Link></li>*!/*/}
//                 {/*    <div>Home / Details / Story / Registry / RSVP</div>*/}
//                 {/*</ul>*/}
//                 <ul>
//                     <li>
//                         <Link to="/">Home</Link>
//                     </li>
//                     <li>
//                         <Link to="/details">Details</Link>
//                     </li>
//                     <li>
//                         <Link to="/gallery">Story</Link>
//                     </li>
//                     <li>
//                         <Link to="/registry">Registry</Link>
//                     </li>
//                     <li>
//                         <Link to="/rsvp">RSVP</Link>
//                     </li>
//                 </ul>
//             </nav>
//         </footer>
//         // <footer className="footer flex flex-col md:flex-row md:items-center">
//         //     <div className="countdown mb-4 md:mb-0 md:mr-8">
//         //         {daysRemaining} days to go!
//         //     </div>
//         //     <nav className="footer-nav">
//         //         <ul className="flex flex-wrap md:flex-nowrap list-none p-0">
//         //             <li className="mr-4">
//         //                 <Link to="/">Home</Link>
//         //             </li>
//         //             <li className="mr-4">
//         //                 <Link to="/details">Details</Link>
//         //             </li>
//         //             <li className="mr-4">
//         //                 <Link to="/gallery">Story</Link>
//         //             </li>
//         //             <li className="mr-4">
//         //                 <Link to="/registry">Registry</Link>
//         //             </li>
//         //             <li>
//         //                 <Link to="/rsvp">RSVP</Link>
//         //             </li>
//         //         </ul>
//         //     </nav>
//         // </footer>

import React, { useState, useEffect } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

function Footer() {
    const [showBackToTop, setShowBackToTop] = useState(false);

    useEffect(() => {
        // Function to handle the scroll event and show/hide the "Back to Top" button
        const handleScroll = () => {
            if (window.scrollY > 200) {
                setShowBackToTop(true);
            } else {
                setShowBackToTop(false);
            }
        };

        // Add the event listener for the scroll event
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Function to scroll to the top of the page when "Back to Top" button is clicked
    const handleBackToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <footer className="p-5 bg-white items-center justify-center flex-col flex text-xl">
            {showBackToTop && (
                <div className="items-center flex font-[Forum]">
                    <ArrowUpwardIcon/>
                    <button className="mx-0.5" onClick={handleBackToTop}>
                        Back to Top
                    </button>
                    <ArrowUpwardIcon/>
                </div>
            )}
            <div className="text-sm text-gray-500 mt-2">Made with ❤️ by Carrington</div>
        </footer>
    );
}

export default Footer;
