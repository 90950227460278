import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {getGuest} from "../firebase";

function No() {

    const navigate = useNavigate();
    const { guestId } = useParams();
    const [guestData, setGuestData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log(guestId);
        getGuest(guestId)
            .then((snapshot) => {
                if (snapshot.exists()) {
                    setGuestData(snapshot.val());
                } else {
                    console.log('Guest not found');
                }
            })
            .catch((error) => {
                console.error('Error fetching guest:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [guestId]);

    useEffect(() => {
        if (!loading && guestData && !guestData.hasResponse) {
            navigate('/rsvp');
        }
        if (!loading && guestData && guestData.hasResponse) {
            if (guestData.rsvp) {
                navigate(`/rsvp/${guestId}/yes`);
            }
        }
    }, [guestData, guestId, loading, navigate]);

    return (
        <div className="mt-32 flex flex-col items-center md:mt-48">
            <p className="text-4xl font-bold text-center mb-4 mx-2">
                We're sorry to hear you can't make it
            </p>
            <p className="text-xl text-center mb-6">
                But appreciate your RSVP
            </p>
            <Link
                to="/"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full text-xl focus:outline-none focus:shadow-outline"
            >
                Return to Home
            </Link>
        </div>
    );
}

export default No;
