import React, {useEffect, useState, useRef} from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';

function Registry() {

    const [items, setItems] = useState([]);

    const [showFilters, setShowFilters] = useState(false);

    const [selectedSortingOption, setSelectedSortingOption] = useState('featured');

    const [showPriceRangeDropdown, setShowPriceRangeDropdown] = useState(false);

    const [showStoreOptionDropdown, setShowStoreOptionDropdown] = useState(false);

    const [showAvailabilityDropdown, setShowAvailabilityDropdown] = useState(false);

    const [showStatusDropdown, setShowStatusDropdown] = useState(false);

    const [showSortingDropdown, setShowSortingDropdown] = useState(false);

    const [selectedFilters, setSelectedFilters] = useState([]);


    const [filterOptions, setFilterOptions] = useState({
        priceRange: [],
        store: [],
        purchased: false,
        mostWanted: false,
    });

    const priceRangeDropdownRef = useRef(null);
    const storeDropdownRef = useRef(null);
    const availabilityDropdownRef = useRef(null);
    const statusDropdownRef = useRef(null);
    const sortingDropdownRef = useRef(null);

    useEffect(() => {
        const closeDropdowns = (event) => {
            const refsToCheck = [
                priceRangeDropdownRef,
                storeDropdownRef,
                availabilityDropdownRef,
                statusDropdownRef,
                sortingDropdownRef,
            ];

            for (const ref of refsToCheck) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowPriceRangeDropdown(false);
                    setShowStoreOptionDropdown(false);
                    setShowAvailabilityDropdown(false);
                    setShowStatusDropdown(false);
                    setShowSortingDropdown(false);
                }
            }
        };

        document.addEventListener('click', closeDropdowns);

        return () => {
            document.removeEventListener('click', closeDropdowns);
        };
    }, []);

    const sortItems = (option) => {
        setSelectedSortingOption(option);

        const sortedItems = [...items]; // Create a copy of the original items array

        if (option === 'featured') {
            // Sort based on your featured logic (if available)
        } else if (option === 'priceHighToLow') {
            sortedItems.sort((a, b) => b.price_int - a.price_int);
        } else if (option === 'priceLowToHigh') {
            sortedItems.sort((a, b) => a.price_int - b.price_int);
        }

        setItems(sortedItems); // Update the state with sorted items
    };

    const handleStoreCheckboxChange = event => {
        const { value, checked } = event.target;

        setFilterOptions(prevOptions => {
            let updatedStores;
            if (checked) {
                updatedStores = [...prevOptions.store, value];
            } else {
                updatedStores = prevOptions.store.filter(store => store !== value);
            }

            return { ...prevOptions, store: updatedStores };
        });

        if (checked) {
            setSelectedFilters((prevFilters) => [...prevFilters, value]);
        } else {
            setSelectedFilters((prevFilters) => prevFilters.filter((filter) => filter !== value));
        }
    };

    const handleFilterChange = event => {
        const { name, value, type, checked } = event.target;
        console.log(event.target);
        let newValue;

        if (type === "checkbox") {
            if (checked) {
                setSelectedFilters((prevFilters) => [...prevFilters, value]);
            } else {
                setSelectedFilters((prevFilters) => prevFilters.filter((filter) => filter !== value));
            }
            if (name === "store") {
                // ... your existing store checkbox logic ...
            } else if (name === "available") {
                newValue = { available: checked, purchased: filterOptions.purchased };
            } else if (name === "purchased") {
                newValue = { available: filterOptions.available, purchased: checked };
            } else if (name === "priceRange") { // Add this condition
                if (checked) {
                    newValue = { priceRange: [...filterOptions.priceRange, value] };
                } else {
                    newValue = { priceRange: filterOptions.priceRange.filter(item => item !== value) };
                }
            } else if (name === "mostWanted") {
                newValue = { mostWanted: checked };
            }
        }

        setFilterOptions(prevOptions => ({ ...prevOptions, ...newValue }));
    };

    useEffect(() => {
        fetch("/CombinedRegistryItems.json")
            .then(response => response.json())
            .then(data => setItems(data))
            .catch(error => console.error("Error fetching JSON:", error));
    }, []);

    const priceRangeOptions = [
        { value: "0-50", label: "$0-49" },
        { value: "50-100", label: "$50-99" },
        { value: "100-150", label: "$100-149" },
        { value: "150-100000", label: "$150+" }
    ];

    return (
        <div>
            <div className="mt-28 md:w-full md:mt-48 items-center justify-center flex">
                <div className="text-center">
                    <p className="text-xl md:text-2xl font-[Forum] text-black">
                        First and foremost, we want to thank you for being a part of our lives! The greatest gift to us is you being there on our special day.
                    </p>
                    <div className="w-4/5 h-px bg-gray-300 dark:bg-gray-600 mx-auto my-8"></div>
                    <h2 className="text-3xl font-[Forum] text-black">Registry Providers</h2>
                </div>
            </div>
            <div className="md:w-full md:justify-center md:grid md:grid-cols-2 lg:grid-cols-3 md:px-32">
                <div className="items-center justify-center flex">
                    <div className="w-10/12 h-48 bg-white border border-gray-200 rounded-lg shadow mt-10 flex flex-col">
                        <a href="https://www.amazon.com/wedding/share/emmaandcarrrington" className="flex-grow flex items-center justify-center">
                            <img className="rounded-t-lg w-4/5" src={require("../logos/AwsRegistryLogo.jpg")} alt="AwsRegistry Logo" />
                        </a>
                        <div className="p-5">
                            <a
                                href="https://www.amazon.com/wedding/share/emmaandcarrrington"
                                className="mt-4 w-full inline-flex items-center justify-center px-4 py-3 text-md font-medium text-black bg-sky-200 rounded-lg hover:bg-black hover:text-white border border-black"
                            >
                                Go to Amazon
                                <svg
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 10"
                                >
                                    <OpenInNewIcon/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="items-center justify-center flex">
                    <div className="w-10/12 h-48 bg-white border border-gray-200 rounded-lg shadow mt-10 flex flex-col">
                        <a href="https://www.target.com/gift-registry/gift-giver?type=WEDDING&registryId=2aee8c50-240f-11ee-892c-294bd222cf0d&registrant=true&regMode=REGST" className="flex-grow flex items-center justify-center">
                            <img className="rounded-t-lg w-1/4" src={require("../logos/TargetLogo.png")} alt="Target Logo" />
                        </a>
                        <div className="px-5 pb-5">
                            <a
                                href="https://www.target.com/gift-registry/gift-giver?type=WEDDING&registryId=2aee8c50-240f-11ee-892c-294bd222cf0d&registrant=true&regMode=REGST"
                                className="mt-4 w-full inline-flex items-center justify-center px-4 py-3 text-md font-medium text-black bg-sky-200 rounded-lg hover:bg-black hover:text-white border border-black"
                            >
                                Go to Target
                                <svg
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 10"
                                >
                                    <OpenInNewIcon />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="items-center justify-center flex">
                    <div className="w-10/12 h-48 bg-white border border-gray-200 rounded-lg shadow mt-10 flex flex-col">
                        <a href="https://shopbellavita.com/apps/giftregistry/registry/280282" className="flex-grow flex items-center justify-center">
                            <img className="w-1/4" src={require("../logos/BellaVitaLogo.png")} alt="Bella Vita Logo" />
                        </a>
                        <div className="px-5 pb-5">
                            <a
                                href="https://shopbellavita.com/apps/giftregistry/registry/280282"
                                className="mt-4 w-full inline-flex items-center justify-center px-4 py-3 text-md font-medium text-black bg-sky-200 rounded-lg hover:bg-black hover:text-white border border-black"
                            >
                                Go to Bella Vita
                                <svg
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 10"
                                >
                                    <OpenInNewIcon />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="items-center justify-center flex">
                    <div className="w-10/12 h-48 bg-white border border-gray-200 rounded-lg shadow mt-10 flex flex-col">
                        <a href="https://www.williams-sonoma.com/registry/2swh57rpht/registry-list.html" className="flex-grow flex items-center justify-center">
                            <img className="w-4/5" src={require("../logos/WilliamsSonomaLogo.png")} alt="Williams Sonoma Logo" />
                        </a>
                        <div className="px-5 pb-5">
                            <a
                                href="https://www.williams-sonoma.com/registry/2swh57rpht/registry-list.html"
                                className="mt-4 w-full inline-flex items-center justify-center px-4 py-3 text-md font-medium text-black bg-sky-200 rounded-lg hover:bg-black hover:text-white border border-black"
                            >
                                Go to Williams Sonoma
                                <svg
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 10"
                                >
                                    <OpenInNewIcon />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="items-center justify-center flex">
                    <div className="w-10/12 h-48 bg-white border border-gray-200 rounded-lg shadow mt-10 flex flex-col">
                        <a href="https://www.potterybarn.com/registry/2swh57rpht/registry-list.html" className="flex-grow flex items-center justify-center">
                            <img className="w-4/5" src={require("../logos/PotteryBarnLogo.png")} alt="Pottery Barn Logo" />
                        </a>
                        <div className="px-5 pb-5">
                            <a
                                href="https://www.potterybarn.com/registry/2swh57rpht/registry-list.html"
                                className="mt-4 w-full inline-flex items-center justify-center px-4 py-3 text-md font-medium text-black bg-sky-200 rounded-lg hover:bg-black hover:text-white border border-black"
                            >
                                Go to Pottery Barn
                                <svg
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 10"
                                >
                                    <OpenInNewIcon />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-4/5 h-px bg-gray-300 dark:bg-gray-600 mx-auto mt-16 mb-6"></div>
            <div className="hidden md:font-[Forum] md:text-3xl md:my-8 md:flex md:justify-center">
                <h1>Registry Items</h1>
            </div>
            <div className="hidden md:flex md:pb-10 md:px-20 md:justify-between">
                <div className="flex relative">
                    <div className="mt-4 bg-white border rounded-lg shadow-md p-4 space-y-2">
                        <button
                            className="flex items-center cursor-pointer"
                            onClick={(event) => {
                                event.stopPropagation();
                                setShowPriceRangeDropdown(prevShowDropdown => !prevShowDropdown)
                            }}
                        >
                            <span className="font-semibold mr-2">Price Range</span>
                            <svg
                                className={`w-4 h-4 transition-transform ${showPriceRangeDropdown ? 'transform rotate-180' : ''}`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 9l-7 7-7-7"
                                ></path>
                            </svg>
                        </button>
                        {showPriceRangeDropdown && (
                            <div ref={priceRangeDropdownRef} className="absolute bg-white border rounded-lg shadow-md mt-2 p-4 space-y-2 top-full left-0 z-20">
                                {priceRangeOptions.map(option => (
                                    <label key={option.value} className="flex items-center">
                                        <input
                                            type="checkbox"
                                            name="priceRange"
                                            value={option.value}
                                            checked={filterOptions.priceRange.includes(option.value)}
                                            onChange={handleFilterChange}
                                            className="mr-2 h-4 w-4 rounded accent-black"
                                        />
                                        {option.label}
                                    </label>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="mt-4 relative ml-4 bg-white border rounded-lg shadow-md p-4 space-y-2">
                        <button
                            className="flex items-center cursor-pointer"
                            onClick={(event) => {
                                event.stopPropagation();
                                setShowStoreOptionDropdown(prevShowDropdown => !prevShowDropdown)
                            }}
                        >
                            <span className="font-semibold mr-2">Store</span>
                            <svg
                                className={`w-4 h-4 transition-transform ${showStoreOptionDropdown ? 'transform rotate-180' : ''}`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 9l-7 7-7-7"
                                ></path>
                            </svg>
                        </button>
                        {showStoreOptionDropdown && (
                            <div ref={storeDropdownRef} className="absolute bg-white border whitespace-normal w-48 rounded-lg shadow-md mt-2 p-4 space-y-2 top-full left-0 z-20">
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="store"
                                        value="Target"
                                        checked={filterOptions.store.includes("Target")}
                                        onChange={handleStoreCheckboxChange}
                                        className="mr-2 h-4 w-4 accent-black focus:ring-opacity-0 focus:border-opacity-0"
                                    />
                                    Target
                                </label>
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="store"
                                        value="Amazon"
                                        checked={filterOptions.store.includes("Amazon")}
                                        onChange={handleStoreCheckboxChange}
                                        className="mr-2 h-4 w-4 rounded accent-black focus:ring-opacity-0 focus:border-opacity-0"
                                    />
                                    Amazon
                                </label>
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="store"
                                        value="Bella Vita"
                                        checked={filterOptions.store.includes("Bella Vita")}
                                        onChange={handleStoreCheckboxChange}
                                        className="mr-2 h-4 w-4 rounded accent-black focus:ring-opacity-0 focus:border-opacity-0"
                                    />
                                    Bella Vita
                                </label>
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="store"
                                        value="Williams Sonoma"
                                        checked={filterOptions.store.includes("Williams Sonoma")}
                                        onChange={handleStoreCheckboxChange}
                                        className="mr-2 h-4 w-4 rounded accent-black focus:ring-opacity-0 focus:border-opacity-0"
                                    />
                                    Williams Sonoma
                                </label>
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="store"
                                        value="Pottery Barn"
                                        checked={filterOptions.store.includes("Pottery Barn")}
                                        onChange={handleStoreCheckboxChange}
                                        className="mr-2 h-4 w-4 rounded accent-black focus:ring-opacity-0 focus:border-opacity-0"
                                    />
                                    Pottery Barn
                                </label>
                            </div>
                        )}
                    </div>
                    <div className="mt-4 relative ml-4 bg-white border rounded-lg shadow-md p-4 space-y-2">
                        <button
                            className="flex items-center cursor-pointer"
                            onClick={(event) => {
                                event.stopPropagation();
                                setShowAvailabilityDropdown(prevShowDropdown => !prevShowDropdown)
                            }}
                        >
                            <span className="font-semibold mr-2">Availability</span>
                            <svg
                                className={`w-4 h-4 transition-transform ${showAvailabilityDropdown ? 'transform rotate-180' : ''}`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 9l-7 7-7-7"
                                ></path>
                            </svg>
                        </button>
                        {showAvailabilityDropdown && (
                            <div ref={availabilityDropdownRef} className="absolute bg-white border whitespace-normal rounded-lg shadow-md mt-2 p-4 space-y-2 top-full left-0 z-20">
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        name="available"
                                        checked={filterOptions.available}
                                        onChange={handleFilterChange}
                                        className="mr-2 h-4 w-4 accent-black"
                                    />
                                    Available
                                </label>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        name="purchased"
                                        checked={filterOptions.purchased}
                                        onChange={handleFilterChange}
                                        className="mr-2 h-4 w-4 accent-black"
                                    />
                                    Purchased
                                </label>
                            </div>
                        )}
                    </div>
                    <div className="relative mt-4 ml-4 bg-white border rounded-lg shadow-md p-4 space-y-2">
                        <button
                            className="flex items-center cursor-pointer"
                            onClick={(event) => {
                                event.stopPropagation();
                                setShowStatusDropdown(prevShowDropdown => !prevShowDropdown)
                            }}
                        >
                            <span className="font-semibold mr-2">Status</span>
                            <svg
                                className={`w-4 h-4 transition-transform ${showStatusDropdown ? 'transform rotate-180' : ''}`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 9l-7 7-7-7"
                                ></path>
                            </svg>
                        </button>
                        {showStatusDropdown && (
                            <div ref={statusDropdownRef} className="absolute bg-white border whitespace-normal w-48 rounded-lg shadow-md mt-2 p-4 space-y-2 top-full left-0 z-20">
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="mostWanted"
                                        checked={filterOptions.mostWanted}
                                        onChange={handleFilterChange}
                                        className="mr-2 h-4 w-4 accent-black"
                                    />
                                    Most Wanted
                                </label>
                            </div>
                        )}
                    </div>
                </div>
                <div className="relative mt-4 bg-white border rounded-lg shadow-md p-4 space-y-2">
                    <button
                        className="flex items-center cursor-pointer"
                        onClick={(event) => {
                            event.stopPropagation();
                            setShowSortingDropdown(prevShowDropdown => !prevShowDropdown);
                            console.log(selectedSortingOption);
                        }}
                    >
                        <span className="font-semibold mr-2">Sorting</span>
                        <svg
                            className={`w-4 h-4 transition-transform ${showSortingDropdown ? 'transform rotate-180' : ''}`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                            ></path>
                        </svg>
                    </button>
                    {showSortingDropdown && (
                        <div ref={sortingDropdownRef} className="absolute bg-white border whitespace-normal w-56 rounded-lg shadow-md mt-2 p-4 space-y-2 top-full -left-28 z-20">
                            <label className="ml-4 flex items-center">
                                <input
                                    type="radio"
                                    name="sortingOption"
                                    value="featured"
                                    checked={selectedSortingOption === 'featured'}
                                    onChange={() => {
                                        sortItems('featured');
                                        setSelectedSortingOption('featured');
                                    }}
                                    className="mr-2 h-4 w-4 accent-black"
                                />
                                <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                        sortItems('featured');
                                        setSelectedSortingOption('featured');
                                    }}
                                >
                                    Featured
                                </span>
                            </label>
                            <label className="ml-4 flex items-center">
                                <input
                                    type="radio"
                                    name="sortingOption"
                                    value="priceHighToLow"
                                    checked={selectedSortingOption === 'priceHighToLow'}
                                    onChange={() => {
                                        setSelectedSortingOption('priceHighToLow');
                                        sortItems('priceHighToLow');
                                    }}
                                    className="mr-2 h-4 w-4 accent-black"
                                />
                                <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                        sortItems('priceHighToLow');
                                        setSelectedSortingOption('priceHighToLow');
                                    }}
                                >
                                    Price: High to Low
                                </span>
                            </label>
                            <label className="ml-4 flex items-center">
                                <input
                                    type="radio"
                                    name="sortingOption"
                                    value="priceLowToHigh"
                                    checked={selectedSortingOption === 'priceLowToHigh'}
                                    onChange={() => {
                                        sortItems('priceLowToHigh');
                                        setSelectedSortingOption('priceLowToHigh');
                                    }}
                                    className="mr-2 h-4 w-4 accent-black"
                                />
                                <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                        sortItems('priceLowToHigh');
                                        setSelectedSortingOption('priceLowToHigh');
                                    }}
                                >
                                    Price: Low to High
                                </span>
                            </label>
                        </div>
                    )}
                </div>
            </div>
            {/*<div className="hidden md:flex md:mx-32">*/}
            {/*    {selectedFilters.map((filter) => (*/}
            {/*        <FilterBubble key={filter} filter={filter} onRemove={handleFilterRemove} />*/}
            {/*    ))}*/}
            {/*</div>*/}
            <div className="flex justify-between items-center px-6 md:mb-0">
                <h2 className="text-2xl font-medium font-[Forum] text-black md:hidden">Registry Items</h2>
                <button
                    className="px-4 py-2 text-md font-medium text-black bg-white rounded-lg hover:bg-black hover:text-white border border-black md:hidden"
                    onClick={() => setShowFilters(prevShowFilters => !prevShowFilters)}
                    style={{ position: 'relative', zIndex: 20 }}
                >
                    Filter/Sort
                </button>
            </div>
            <div className={`relative mt-4 px-4 md:mt-4 ${showFilters ? 'block' : 'hidden'}`}>
                <div className="bg-white border rounded-lg shadow-md p-4 space-y-2">
                    <button
                        className="absolute top-0 right-0 pr-8 pt-6 text-black hover:text-black focus:outline-none"
                        onClick={() => setShowFilters(false)}
                    >
                        <CloseIcon />
                    </button>
                    <label className="flex flex-col">
                        <span className="font-semibold">Price Range:</span>
                        <div className="ml-4 space-y-1">
                            {priceRangeOptions.map(option => (
                                <label key={option.value} className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="priceRange"
                                        value={option.value}
                                        checked={filterOptions.priceRange.includes(option.value)}
                                        onChange={handleFilterChange}
                                        className="mr-2 h-4 w-4 rounded accent-black"
                                    />
                                    {option.label}
                                </label>
                            ))}
                        </div>
                    </label>
                    <label className="flex flex-col space-y-1">
                        <span className="font-semibold">Stores:</span>
                        <label className="ml-4 flex items-center space-x-2">
                            <input
                                type="checkbox"
                                name="store"
                                value="Target"
                                checked={filterOptions.store.includes("Target")}
                                onChange={handleStoreCheckboxChange}
                                className="mr-2 h-4 w-4 accent-black focus:ring-opacity-0 focus:border-opacity-0"
                            />
                            Target
                        </label>
                        <label className="ml-4 flex items-center space-x-2">
                            <input
                                type="checkbox"
                                name="store"
                                value="Amazon"
                                checked={filterOptions.store.includes("Amazon")}
                                onChange={handleStoreCheckboxChange}
                                className="mr-2 h-4 w-4 rounded accent-black focus:ring-opacity-0 focus:border-opacity-0"
                            />
                            Amazon
                        </label>
                        <label className="ml-4 flex items-center space-x-2">
                            <input
                                type="checkbox"
                                name="store"
                                value="Bella Vita"
                                checked={filterOptions.store.includes("Bella Vita")}
                                onChange={handleStoreCheckboxChange}
                                className="mr-2 h-4 w-4 rounded accent-black focus:ring-opacity-0 focus:border-opacity-0"
                            />
                            Bella Vita
                        </label>
                        <label className="ml-4 flex items-center space-x-2">
                            <input
                                type="checkbox"
                                name="store"
                                value="Williams Sonoma"
                                checked={filterOptions.store.includes("Williams Sonoma")}
                                onChange={handleStoreCheckboxChange}
                                className="mr-2 h-4 w-4 rounded accent-black focus:ring-opacity-0 focus:border-opacity-0"
                            />
                            Williams Sonoma
                        </label>
                        {/* Add more store options as needed */}
                    </label>
                    <label className="flex flex-col space-y-1">
                        <span className="font-semibold">Availability:</span>
                        <label className="ml-4 flex items-center space-x-2">
                            <input
                                type="checkbox"
                                name="available"
                                checked={filterOptions.available}
                                onChange={handleFilterChange}
                                className="mr-2 h-4 w-4 accent-black"
                            />
                            Available
                        </label>
                        <label className="ml-4 flex items-center space-x-2">
                            <input
                                type="checkbox"
                                name="purchased"
                                checked={filterOptions.purchased}
                                onChange={handleFilterChange}
                                className="mr-2 h-4 w-4 accent-black"
                            />
                            Purchased
                        </label>
                    </label>
                    <label className="flex flex-col space-y-1">
                        <span className="font-semibold">Status:</span>
                        <label className="ml-4 flex items-center">
                            <input
                                type="checkbox"
                                name="mostWanted"
                                checked={filterOptions.mostWanted}
                                onChange={handleFilterChange}
                                className="mr-2 h-4 w-4 accent-black"
                            />
                            Most Wanted
                        </label>
                    </label>
                    <div className="flex flex-col space-y-2">
                        <span className="font-semibold">Sort By:</span>
                        <label className="ml-4 flex items-center">
                            <input
                                type="radio"
                                name="sortingOption"
                                value="featured"
                                checked={selectedSortingOption === 'featured'}
                                onChange={() => sortItems('featured')}
                                className="mr-2 h-4 w-4 accent-black"
                            />
                            Featured
                        </label>
                        <label className="ml-4 flex items-center">
                            <input
                                type="radio"
                                name="sortingOption"
                                value="priceHighToLow"
                                checked={selectedSortingOption === 'priceHighToLow'}
                                onChange={() => sortItems('priceHighToLow')}
                                className="mr-2 h-4 w-4 accent-black"
                            />
                            Price: High to Low
                        </label>
                        <label className="ml-4 flex items-center">
                            <input
                                type="radio"
                                name="sortingOption"
                                value="priceLowToHigh"
                                checked={selectedSortingOption === 'priceLowToHigh'}
                                onChange={() => sortItems('priceLowToHigh')}
                                className="mr-2 h-4 w-4 accent-black"
                            />
                            Price: Low to High
                        </label>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap justify-between md:grid md:grid-cols-4 md:mx-32">
                {items.map((item, index) => {
                    const meetsStoreFilter = filterOptions.store.length === 0 || filterOptions.store.includes(item.source);
                    // const meetsPurchasedFilter = !filterOptions.purchased || (filterOptions.purchased === "available" && (item.requested_quantity - item.purchased_quantity) > 0);
                    const meetsAvailableFilter = filterOptions.available
                        ? item.purchased_quantity < item.requested_quantity
                        : true;

                    const meetsPurchasedFilter = filterOptions.purchased
                        ? item.purchased_quantity >= item.requested_quantity
                        : true;

                    const priceRangeValues = filterOptions.priceRange.map(range => range.split("-"));

                    const meetsPriceRange = filterOptions.priceRange.length === 0 || priceRangeValues.some(([min, max]) => {
                        const priceInt = parseInt(item.price_int);
                        return priceInt >= parseInt(min) && priceInt <= parseInt(max);
                    });

                    const meetsMostWantedFilter = !filterOptions.mostWanted || item.most_wanted;

                    if (meetsStoreFilter && meetsMostWantedFilter && meetsPurchasedFilter && meetsAvailableFilter && meetsPriceRange) {
                        return (
                            <a key={index} className="card mt-3 hover:shadow-md h-96 md:w-10/12 md:h-full px-1.5 pt-3 w-1/2 rounded-lg" href={item.buy_url} target="_blank" rel="noreferrer">
                                <img
                                    className={`card__icon rounded-t-lg ${item.requested_quantity - item.purchased_quantity === 0 ? "filter grayscale opacity-50" : ""}`}
                                    src={item.image_url}
                                    alt="Item Icon"
                                />
                                <h1 className="card__header line-clamp-2">{item.title}</h1>
                                <h1 className="text-sm text-gray-500">{item.source}</h1>
                                <h1 className={item.requested_quantity - item.purchased_quantity === 0 ? "text-center bg-green-400 rounded pl-0.5" : ""}>
                                    {item.requested_quantity - item.purchased_quantity > 0 ? `Still needs: ${item.requested_quantity - item.purchased_quantity}` : "Purchased ✅"}
                                </h1>
                                <p className="font-semibold pt-2">{item.price_string}</p>
                                {item.most_wanted &&
                                    <div className="mt-2 bg-yellow-200 rounded-md px-2 py-1 text-sm font-medium">Most
                                        Wanted</div>}
                            </a>
                        );
                    }

                    return null; // If item doesn't meet both filters, don't render it
                })}
            </div>
        </div>
    );
}

export default Registry;