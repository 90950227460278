import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getIdByAddress, getGuest } from '../firebase'; // Assuming you have getIdByAddress and getGuest in your firebase module
import TextField from '@mui/material/TextField';

function RSVPLandingPage() {
    const navigate = useNavigate();
    const [addressNumber, setAddressNumber] = useState('');
    const [lastName, setLastName] = useState('');
    const [error, setError] = useState(null);
    const [lastNameError, setLastNameError] = useState(null);
    const [matchingGuests, setMatchingGuests] = useState(null);
    const [matchingGuestsByLastName, setMatchingGuestsByLastName] = useState(null);

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (!/^\d+$/.test(addressNumber)) {
            setError('Please enter a valid numeric Address Number.');
            return;
        }

        try {
            // Check if last name is set
            if (lastName) {
                const snapshot = await getIdByAddress(addressNumber);

                if (snapshot.exists()) {
                    const data = snapshot.val();

                    // Convert the object values to an array
                    const dataArray = Array.isArray(data) ? data : Object.values(data);

                    // Check if lastName matches any of the last names in dataArray
                    const matchingLastNameGuests = dataArray.filter(guest => guest.lastName.toLowerCase() === lastName.toLowerCase());

                    if (matchingLastNameGuests.length === 1) {
                        navigate(`/rsvp/${matchingLastNameGuests[0].guestId}`);
                    } else if (matchingLastNameGuests.length > 1) {
                        // If there are multiple matches, display options for user to select
                        setMatchingGuestsByLastName(matchingLastNameGuests);
                        setLastNameError(null);
                    } else {
                        setLastNameError('No matching guests found with the provided last name.');
                    }
                } else {
                    setError('Address Number not found');
                }
            } else {
                // If last name is not set, proceed as before
                const snapshot = await getIdByAddress(addressNumber);

                if (snapshot.exists()) {
                    const data = snapshot.val();

                    const dataArray = Array.isArray(data) ? data : Object.values(data);

                    if (dataArray.length === 1) {
                        navigate(`/rsvp/${dataArray[0].guestId}`);
                    } else if (dataArray.length > 1) {
                        setMatchingGuests(dataArray);
                        setError(null);
                    } else {
                        setError('No matching guests found.');
                    }
                } else {
                    setError('Street number not found');
                }
            }
        } catch (error) {
            console.error('Error fetching guest:', error);
            setError('An error occurred while fetching guest data.');
        }
    };

    const handleGuestClick = (guestId) => {
        navigate(`/rsvp/${guestId}`);
    };


    return (
        <div className="mt-32 flex flex-col items-center md:mt-48">
            <form
                onSubmit={handleFormSubmit}
                className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 md:w-1/3"
            >
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Please enter your street number:
                    </label>
                    <TextField
                        label="Street Number"
                        variant="outlined"
                        fullWidth
                        name="addressNumber"
                        value={addressNumber}
                        onChange={(e) => setAddressNumber(e.target.value)}
                        required
                        error={error !== null}
                        helperText={error}
                    />
                </div>
                {matchingGuests && matchingGuests.length > 1 && (
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Please enter your last name:
                        </label>
                        <TextField
                            label="Last Name"
                            variant="outlined"
                            fullWidth
                            name="lastName"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            error={lastNameError !== null}
                            helperText={lastNameError !== null ? lastNameError : ''}
                        />
                    </div>
                )}
                {!matchingGuestsByLastName ? (
                    <div className="flex justify-center">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="submit"
                        >
                            Submit
                        </button>
                    </div>
                ) : (
                    <div className="flex flex-col items-center">
                        <p className="mb-2 font-bold">Please select your name:</p>
                        {matchingGuestsByLastName.map((guest) => (
                            <div key={guest.guestId} className="mb-2">
                                <button
                                    onClick={() => handleGuestClick(guest.guestId)}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                >
                                    {guest.firstName}
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </form>
        </div>
    );
}

export default RSVPLandingPage;
