import React from "react";

function Story() {
    return (
        <div className="mt-28 md:mt-48 text-center items-center justify-center flex-col flex">
            <img
                src={require("../photos/OurStory.jpg")}
                alt="Our Story"
                className="mx-auto rounded-full w-64 h-64 md:w-96 md:h-96 object-cover shadow-md shadow-gray-500"
            />
            <h1 className="text-3xl font-semibold mt-4">Our Story</h1>
            <div className="md:w-1/2">
                <p className="text-lg my-4 mx-8">
                    It all started in May of 2017 at the Shelby Farms FedEx Event Center,
                    where Emma thought it would be a good idea to crash a birthday party.
                    6+ years later, she will still say that was the best decision she ever made.
                    Although they went to different high schools, Emma had friends on her soccer
                    team that went to Carrington’s school. One evening, while Emma was with some
                    of her soccer teammates, they collectively decided to attend a birthday party,
                    even though Emma hadn't received an invitation since she attended a different school.
                    Minutes into the party, Carrington and Emma started talking and sparks flew immediately.
                    They were inseparable the entire night, and they both felt as though they had known each
                    other for years. It must have truly been love at first sight, because the next day
                    Carrington asked Emma on a date!
                </p>
                <p className="text-lg mt-8 mx-8">
                    The two dated for all of high school before starting 4 years of long-distance as Carrington
                    attended Georgia Tech and Emma attended Auburn. On June 17, 2023, Carrington convinced Emma
                    that there was a family reunion in town, because why else would he drive 5 hours to Memphis?
                    Little did she know that was just to get a good photoshoot location. The two pulled into the
                    “reunion” spot, and when she realized there was no one coming to pick them up, she turned
                    around and he was on one knee.
                </p>
                <p className="text-lg mt-8 mx-8">
                    Carrington and Emma are both so thankful that the Lord brought them together, and
                    they can’t wait to celebrate their love and the covenant of marriage with you!
                </p>
            </div>
        </div>
    );
}

export default Story;