import React from "react";
import "./Home.css"
import {Link} from "react-router-dom";

function Home() {

    const countdownDate = new Date('2024-06-01T00:00:00-05:00');
    const today = new Date();
    const todayCT = new Date(today.toLocaleString('en-US', { timeZone: 'America/Chicago' }));
    const countdownDateCT = new Date(countdownDate.toLocaleString('en-US', { timeZone: 'America/Chicago' }));
    const timeDiff = countdownDateCT.getTime() - todayCT.getTime();
    const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return (
        <div>
            <div className="relative">
                <div className="hidden md:flex bg-hero-image h-110v bg-cover bg-center" />
                <div className="md:hidden bg-mobile-hero h-110v bg-cover bg-center" />
                <div className="hero-content top-2/4 left-2/4 absolute text-center w-full">
                    <h1 className="title text-center tracking-xl text-black text-2xl md:text-5xl uppercase font-[Forum]">Emma & Carrington</h1>
                    <p className="date tracking-widest text-center text-black text-2xl font-[Forum]">06.01.24</p>
                </div>
            </div>
            <div className="text-center pb-6 my-8">
                <p className="text-center font-[Forum] text-2xl py-3 md:text-3xl md:py-5">Only {daysRemaining} more days!</p>
                <Link to="/rsvp" className="inline-block text-xl md:text-2xl bg-transparent hover:bg-black text-black font-[Forum] hover:text-white py-2 px-4 border border-black hover:border-transparent rounded">
                    RSVP Now
                </Link>
            </div>
            <div className="container mx-auto px-5 md:py-2 md:px-4 ">
                <div className="-m-1 flex flex-wrap md:-m-2">
                    <div className="flex w-1/2 flex-wrap">
                        <div className="w-1/2 p-1 md:p-2">
                            <img
                                alt="gallery"
                                className="block h-full w-full rounded-lg object-cover object-center"
                                src={require("../photos/img4.jpeg")}/>
                        </div>
                        <div className="w-1/2 p-1 md:p-2">
                            <img
                                alt="gallery"
                                className="block h-full w-full rounded-lg object-cover object-center"
                                src={require("../photos/img6.jpeg")}/>
                        </div>
                        <div className="w-full p-1 md:p-2">
                            <img
                                alt="gallery"
                                className="block h-full w-full rounded-lg object-cover object-center"
                                src={require("../photos/img3.JPG")}/>
                        </div>
                    </div>
                    <div className="flex w-1/2 flex-wrap">
                        <div className="w-full p-1 md:p-2">
                            <img
                                alt="gallery"
                                className="block h-full w-full rounded-lg object-cover object-center"
                                src={require("../photos/img9.jpeg")}/>
                        </div>
                        <div className="w-1/2 p-1 md:p-2">
                            <img
                                alt="gallery"
                                className="block h-full w-full rounded-lg object-cover object-center"
                                src={require("../photos/img7.jpeg")}/>
                        </div>
                        <div className="w-1/2 p-1 md:p-2">
                            <img
                                alt="gallery"
                                className="block h-full w-full rounded-lg object-cover object-center"
                                src={require("../photos/img8.jpeg")}/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Home;