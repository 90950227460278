// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, child, get, update, orderByChild, equalTo, query } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// import { fs } from "fs";
// import * as fs from "fs";
// import { createReadStream, promises as fsPromises } from 'fs';
// import { pipeline } from 'stream';
// import csv from 'csv-parser';

// const twilio = require('twilio');
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAVEe7Y1Lb3Yic4PPn0NZ93ccEjmQM98y8",
    authDomain: "cande-wedding-rsvp.firebaseapp.com",
    databaseURL: "https://cande-wedding-rsvp-default-rtdb.firebaseio.com",
    projectId: "cande-wedding-rsvp",
    storageBucket: "cande-wedding-rsvp.appspot.com",
    messagingSenderId: "153243040367",
    appId: "1:153243040367:web:4f7ab60e53e2b96210eb9a",
    measurementId: "G-T27ZLBYW10"
};
// const client = twilio('AC9c2ba0ecaaed7af099414d5244b4417f', '11b01af588937b8d00ccd233cf79796e');
//
// async function sendTextMessage(body) {
//     try {
//         await client.messages.create({
//             body: body,
//             from: '+18889264503',
//             to: '+19016512615'
//         });
//         console.log('Text message sent successfully!');
//     } catch (error) {
//         console.error('Error sending text message:', error);
//     }
// }

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// function writeGuestResponse(guestId, rsvp, firstName, lastName) {
//     const db = getDatabase();
//     const reference = ref(db, 'guests/' + guestId);
//
//     set(reference, {
//         guestId: guestId,
//         rsvp: false,
//         firstName: firstName,
//         lastName: lastName,
//         hasResponse: false
//     });
// }

export function updateGuestResponse(guestId, rsvp) {
    const db = getDatabase();
    const updates = {};
    updates['/guests/' + guestId + '/rsvp'] = rsvp;
    updates['/guests/' + guestId + '/hasResponse'] = true;
    // console.log(updates)
    // const body = `${guestId}: ${rsvp ? 'Yes' : 'No'}`
    // sendTextMessage(body);
    return update(ref(db), updates);
}
export function updateGuestResponseWithPlusOne(guestId, rsvp, plusOneResponse) {
    const db = getDatabase();
    const updates = {};
    updates['/guests/' + guestId + '/rsvp'] = rsvp;
    updates['/guests/' + guestId + '/plusOneRsvp'] = plusOneResponse;
    updates['/guests/' + guestId + '/hasResponse'] = true;
    // const body = `${guestId}: ${rsvp ? 'Yes' : 'No'} with plus one: ${plusOneResponse ? 'Yes' : 'No'}`;
    // sendTextMessage(body);
    // console.log(updates)

    return update(ref(db), updates);
}

export function updateGuestResponseWithKids(guestId, childResponses) {
    const db = getDatabase();
    const updates = {};
    for (const childId in childResponses) {
        updates[`/guests/${guestId}/kids/${childId}`] = childResponses[childId];
    }
    // const body = `${guestId}: ${updates.toString()}`;
    // sendTextMessage(body);
    // console.log(updates)

    return update(ref(db), updates);
}

export function updateSpouseResponse(guestId, spouseResponse) {
    const db = getDatabase();
    const updates = {};
    updates[`/guests/${guestId}/spouseRsvp`] = spouseResponse;
    // const body = `${guestId}: ${spouseResponse ? 'Yes' : 'No'}`;
    // console.log(updates)

    return update(ref(db), updates);
}

export function getIdByAddress(addressNumber) {
    const db = getDatabase();
    const dbRef = ref(db);

    const guestsQuery = query(
        child(dbRef, 'guests'),
        orderByChild('address'),
        equalTo(addressNumber)
    );
    const out = get(guestsQuery);
    // console.log(out);
    return out;
}

export function getGuest(guestId) {
    return new Promise((resolve, reject) => {
        const dbRef = ref(getDatabase());
        get(child(dbRef, `guests/${guestId}`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    // console.log(snapshot.val());
                    resolve(snapshot);
                } else {
                    // console.log("No data available");
                    resolve(null); // Resolve with null if guest not found
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
}

// processCSV('/Users/carringtonkelsey/Downloads/Wedding Guest List.csv').then(r => console.log(r));
//
//
// async function generateRandomPin() {
//     return Math.floor(100000 + Math.random() * 900000);
// }
//
// async function insertGuestData(firstName, lastName, address, hasSpouse, hasPlusOne, hasKids, email, phoneNumber) {
//     try {
//         const guestId = await generateRandomPin();
//         const db = getDatabase();
//         const reference = ref(db, 'guests/' + guestId);
//
//         console.log(hasSpouse);
//         console.log(hasPlusOne);
//
//         if (!hasPlusOne) {
//             hasPlusOne = 'no';
//         }
//         if (!hasKids) {
//             hasKids = 'no';
//         }
//         if (!hasSpouse) {
//             hasSpouse = 'no';
//         }
//
//         // Convert 'yes' to true for boolean fields
//         const formattedData = {
//             guestId: guestId,
//             firstName: firstName,
//             lastName: lastName,
//             address: address,
//             hasSpouse: hasSpouse === 'yes',
//             hasPlusOne: hasPlusOne === 'yes',
//             hasKids: hasKids === 'yes',
//             email: email,
//             phoneNumber: phoneNumber,
//             rsvp: false,
//             hasResponse: false,
//         };
//
//         console.log(formattedData);
//
//         await set(reference, formattedData);
//         console.log(`Data written successfully for ${firstName}.`);
//     } catch (error) {
//         console.error('Error writing data:', error);
//     }
// }
//
// async function processCSV(csvFilePath) {
//     let rowCount = 0; // Initialize a counter for rows processed
//
//     try {
//         await new Promise((resolve, reject) => {
//             createReadStream(csvFilePath)
//                 .pipe(csv())
//                 .on('data', async (row) => {
//                     // Increment the counter for each row
//                     rowCount++;
//
//                     // Grab the current line of the CSV and print it to the console
//                     console.log('Current line of the CSV:', row);
//
//                     // Generate a random six-digit pin and log it to the console
//                     await insertGuestData(
//                         row['firstName'],
//                         row['lastName'],
//                         row['address'],
//                         row['hasSpouse'],
//                         row['hasPlusOne'],
//                         row['hasKids'],
//                         row['email'],
//                         row['phoneNumber']
//                     );
//                 })
//                 .on('end', () => {
//                     console.log(`CSV processing complete, baby. ${rowCount} rows written to the database.`);
//                     resolve();
//                 })
//                 .on('error', (error) => {
//                     console.error('Error processing CSV:', error);
//                     reject(error);
//                 });
//         });
//     } catch (error) {
//         console.error('Error processing CSV:', error);
//     }
// }
