import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Home from './Home/Home';
import Details from './Details/Details';
import Story from './Gallery/Story';
import Registry from './Registry/Registry';
import RSVP from './RSVP/RSVP';
import RSVPLandingPage from "./RSVP/RSVPLandingPage";
import Yes from "./RSVP/Yes"
import No from "./RSVP/No"

function App() {
    return (
        <BrowserRouter>
            <div className="app">
                <Header />
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/details" element={<Details />} />
                    <Route path="/story" element={<Story />} />
                    <Route path="/registry" element={<Registry />} />
                    <Route path="/rsvp" element={<RSVPLandingPage />} />
                    <Route path="/rsvp/:guestId/yes" element={<Yes />} />
                    <Route path="/rsvp/:guestId/no" element={<No />} />
                    <Route path="/rsvp/:guestId" element={<RSVP />} />
                </Routes>
                <Footer />
            </div>
        </BrowserRouter>
    );
}

export default App;
