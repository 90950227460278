import React, { useRef } from "react";

function Details() {

    const faqRef = useRef(null);
    const travelRef = useRef(null);
    const thingsToDoRef = useRef(null);

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    return (
        <div className="mt-28 md:mt-56">
            <div className="flex justify-center mx-3 mb-5 text-xl md:text-2xl">
                <button className="rounded-full border border-black p-4 mx-1.5" onClick={() => scrollToSection(faqRef)}>FAQ</button>
                <button className="rounded-full border border-black p-4 mx-1.5" onClick={() => scrollToSection(travelRef)}>Travel</button>
                <button className="rounded-full border border-black p-4 mx-1.5" onClick={() => scrollToSection(thingsToDoRef)}>Things to Do/Eat</button>
            </div>

            <div ref={faqRef} className="flex mx-3 my-10 font-[Forum] flex-col items-center">
                <div className="bg-blue-500 text-white font-semibold text-2xl px-8 py-2 rounded-md mb-4 md:mb-10 text-center">FAQ</div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="hidden w-1/2 mr-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/RobinshawFront.jpeg")} alt="The Robinshaw" />
                    </div>
                    <div className="justify-center text-center md:text-right">
                        <div className="font-semibold mb-2 text-2xl">Time and Location?</div>
                        <p className="text-lg">The ceremony will be held at <a className="underline" href="https://maps.app.goo.gl/5Uq2CPwvgoij3ZCs9">The Robinshaw</a> on June 1st, 2024, at 5:30 PM.</p>
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="justify-center text-center md:text-left">
                        <div className="font-semibold mb-2 text-2xl">Reception Location?</div>
                        <p className="text-lg">The reception will also be at <a className="underline" href="https://maps.app.goo.gl/5Uq2CPwvgoij3ZCs9">The Robinshaw</a>, immediately following the ceremony.</p>
                    </div>
                    <div className="hidden w-1/2 ml-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/ReceptionImage.jpeg")} alt="Reception" />
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="hidden w-1/2 mr-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/ClosetImage.jpeg")} alt="Attire" />
                    </div>
                    <div className="justify-center text-center md:text-right">
                        <div className="font-semibold mb-2 text-2xl">Attire?</div>
                        <p className="text-lg">The attire for the wedding is black tie optional.</p>
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="justify-center text-center md:text-left">
                        <div className="font-semibold mb-2 text-2xl">May I bring my children to the wedding?</div>
                        <p className="text-lg">We kindly ask that you only bring your children if they are included on the invitation received.</p>
                    </div>
                    <div className="hidden w-1/2 ml-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/KidsImage.jpg")} alt="Family" />
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="hidden w-1/2 mr-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/PlusOne.jpeg")} alt="Plus One" />
                    </div>
                    <div className="justify-center text-center md:text-right">
                        <div className="font-semibold mb-2 text-2xl">May I bring a plus-one to the wedding?</div>
                        <p className="text-lg">We kindly ask that only those listed on the RSVP attend. If you have been given a plus one, it will be specified on your RSVP as “guest.”</p>
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="justify-center text-center md:text-left">
                        <div className="font-semibold mb-2 text-2xl">Will the wedding be indoors or outdoors?</div>
                        <p className="text-md text-lg">Both the ceremony and reception will be indoors; a cocktail hour in between the two will be outdoors.</p>
                    </div>
                    <div className="hidden w-1/2 ml-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/Indoor.jpeg")} alt="Indoor Img" />
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="hidden w-1/2 mr-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/RsvpDate.jpg")} alt="RSVP Card" />
                    </div>
                    <div className="justify-center text-center md:text-right">
                        <div className="font-semibold mb-2 text-2xl">What date should I RSVP by?</div>
                        <p className="text-lg">We kindly ask that you RSVP by April 20, 2024.</p>
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="justify-center text-center md:text-left">
                        <div className="font-semibold mb-2 text-2xl">Is there parking available?</div>
                        <p className="text-md text-lg">Yes, there will be plenty of parking provided at the venue, as well as a golf cart service if extra lots are necessary.</p>
                    </div>
                    <div className="hidden w-1/2 ml-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/ParkingLot.png")} alt="Parking Lot" />
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="hidden w-1/2 mr-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/Weather.jpeg")} alt="Weather" />
                    </div>
                    <div className="justify-center text-center md:text-right">
                        <div className="font-semibold mb-2 text-2xl">What is the weather like in the area?</div>
                        <p className="text-lg">Summers in Memphis can be quite hot, but the only thing that will be outdoors is the cocktail hour, as the ceremony and reception will be indoors.</p>
                    </div>
                </div>
            </div>
            <div ref={travelRef} className="w-4/5 h-px bg-gray-300 mx-auto my-8" />
            <div className="flex mx-3 my-10 font-[Forum] flex-col items-center">
                <div className="bg-blue-500 text-white font-semibold text-2xl px-8 py-2 rounded-md mb-4 md:mb-10 text-center">Travel</div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="justify-center text-center md:text-left">
                        <div className="font-semibold mb-2 text-2xl"><a className="underline" href="https://www.marriott.com/en-us/hotels/memvl-courtyard-memphis-collierville/overview/">Marriott Courtyard</a></div>
                    </div>
                    <div className="hidden w-1/2 ml-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/MarriottCourtyard.jpg")} alt="Courtyard Marriott" />
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="hidden w-1/2 mr-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/FairfieldInn.jpg")} alt="Fairfield Inn" />
                    </div>
                    <div className="justify-center text-center md:text-right">
                        <div className="font-semibold mb-2 text-2xl"><a className="underline" href="https://www.marriott.com/en-us/hotels/memfv-fairfield-inn-and-suites-memphis-collierville/overview/">Fairfield Inn</a></div>
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="justify-center text-center md:text-left">
                        <div className="font-semibold mb-2 text-2xl"><a className="underline" href="https://centralstationmemphis.com/">Central Station</a></div>
                    </div>
                    <div className="hidden w-1/2 mr-0.5 md:flex md:flex-row-reverse">
                        <img className="rounded-lg" src={require("../photos/CentralStation.jpg")} alt="Central Station" />
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="hidden w-1/2 mr-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/HamptonInnCollierville.jpg")} alt="Hampton Inn - Collierville" />
                    </div>
                    <div className="justify-center text-center md:text-right">
                        <div className="font-semibold mb-2 text-2xl"><a className="underline" href="https://www.hilton.com/en/hotels/memcvhx-hampton-memphis-collierville/">Hampton Inn</a></div>
                    </div>
                </div>
            </div>
            <div ref={thingsToDoRef} className="w-4/5 h-px bg-gray-300 mx-auto my-8" />
            <div className="flex mx-3 my-10 font-[Forum] flex-col items-center">
                <div className="bg-blue-500 text-white font-semibold text-2xl px-8 py-2 rounded-md mb-4 md:mb-10 text-center">Things To Do/Eat</div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="justify-center text-center md:text-left">
                        <div className="font-semibold mb-2 text-2xl"><a className="underline" href="https://www.shelbyfarmspark.org/">Shelby Farms Park</a></div>
                        <p className="text-md text-lg">This park of 4,500+ acres is full of walking/running trails, several lakes with water activities, and beautiful scenery.</p>
                    </div>
                    <div className="hidden w-1/2 ml-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/ShelbyFarms.jpeg")} alt="Shelby Farms Park" />
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="hidden w-1/2 mr-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/Peabody.jpeg")} alt="The Peabody" />
                    </div>
                    <div className="justify-center text-center md:text-right">
                        <div className="font-semibold mb-2 text-2xl"><a className="underline" href="https://www.peabodymemphis.com/">The Peabody Hotel</a></div>
                        <p className="text-lg">The Peabody has an amazing rooftop overlooking downtown Memphis, and you
                            won't want to miss the famous Peabody Ducks in the hotel lobby or in their house on the roof!
                        </p>
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="justify-center text-center md:text-left">
                        <div className="font-semibold mb-2 text-2xl"><a className="underline" href="https://maps.app.goo.gl/z5jaV6CU6pSgSdvR7">Gibson's Donuts</a></div>
                        <p className="text-md text-lg">This is Emma and Carrington’s favorite dessert spot in Memphis. Their donuts and apple fritters are to die for!</p>
                    </div>
                    <div className="hidden w-1/2 ml-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/Gibsons.jpeg")} alt="Gibson's Donuts" />
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="hidden w-1/2 mr-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/Graceland.jpeg")} alt="Graceland" />
                    </div>
                    <div className="justify-center text-center md:text-right">
                        <div className="font-semibold mb-2 text-2xl"><a className="underline" href="https://www.graceland.com/">Graceland</a></div>
                        <p className="text-lg">Visit Elvis Presley's house and learn about the King of Rock and Roll!
                        </p>
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="justify-center text-center md:text-left">
                        <div className="font-semibold mb-2 text-2xl"><a className="underline" href="https://bealestreet.com/">Beale Street</a></div>
                        <p className="text-lg">Stop by this lively entertainment of downtown while you're in Memphis!</p>
                    </div>
                    <div className="hidden w-1/2 ml-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/BealeStreet.jpeg")} alt="Beale Street" />
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="hidden w-1/2 mr-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/BassPro.jpg")} alt="Bass Pro by Landon" />
                    </div>
                    <div className="justify-center text-center md:text-right">
                        <div className="font-semibold mb-2 text-2xl"><a className="underline" href="https://big-cypress.com/bass-pro-shops/">Bass Pro Shops at The Pyramid</a></div>
                        <p className="text-lg">Come and see the largest pyramid in this hemisphere!
                        </p>
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="justify-center text-center md:text-left">
                        <div className="font-semibold mb-2 text-2xl"><a className="underline" href="https://www.memphiszoo.org/">The Memphis Zoo</a></div>
                        <p className="text-lg">This is fun for all ages!</p>
                    </div>
                    <div className="hidden w-1/2 ml-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/MemphisZoo.jpeg")} alt="Memphis Zoo" />
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="hidden w-1/2 mr-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/RiverCrossing.jpeg")} alt="Big River Crossing" />
                    </div>
                    <div className="justify-center text-center md:text-right">
                        <div className="font-semibold mb-2 text-2xl"><a className="underline" href="https://www.bigrivercrossing.com/">Big River Crossing</a></div>
                        <p className="text-lg">If you're looking for a walk with a view, this is the spot for you.</p>
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="justify-center text-center md:text-left">
                        <div className="font-semibold mb-2 text-2xl">Healthy Food Recommendations</div>
                        <p className="text-lg"><a className="underline" href="https://www.thecitysilo.com/">City Silo</a></p>
                        <p className="text-lg"><a className="underline" href="https://cheffies.com/">Cheffie's Café</a></p>
                        <p className="text-lg"><a className="underline" href="https://www.wildbeetsalads.com/">Wild Beet Salad Co.</a></p>
                    </div>
                    <div className="hidden w-1/2 ml-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/CitySilo.jpeg")} alt="City Silo"/>
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="hidden w-1/2 mr-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/Flight.jpeg")} alt="Flight Restaurant" />
                    </div>
                    <div className="justify-center text-center md:text-right">
                        <div className="font-semibold mb-2 text-2xl">Fine Dining Recommendations</div>
                        <p className="text-lg"><a className="underline" href="https://flightmemphis.com/">Flight</a></p>
                        <p className="text-lg"><a className="underline" href="https://www.southernsocial.com/">Southern Social</a></p>
                        <p className="text-lg"><a className="underline" href="https://www.coastalfishcompany.com/">Coastal Fish Company</a></p>
                        <p className="text-lg"><a className="underline" href="https://www.porchandparlor.com/">Porch & Parlor</a></p>
                        <p className="text-lg"><a className="underline" href="https://hogandhominy.com/">Hog & Hominy</a></p>
                    </div>
                </div>
                <div className="flex mx-3 my-2 md:my-10 md:justify-between md:w-11/12 md:items-center">
                    <div className="justify-center text-center md:text-left">
                        <div className="font-semibold mb-2 text-2xl">Uniquely Memphis Recommendations</div>
                        <p className="text-lg"><a className="underline" href="https://hogsfly.com/">Rendezvous</a></p>
                        <p className="text-lg"><a className="underline" href="https://eatcbq.com/">Central Barbecue</a></p>
                        <p className="text-lg"><a className="underline" href="https://hueyburger.com/">Huey's</a></p>
                        <p className="text-lg"><a className="underline" href="https://www.elwoodsshack.com/">Elwood's Shack</a></p>
                    </div>
                    <div className="hidden w-1/2 ml-0.5 md:flex">
                        <img className="rounded-lg" src={require("../photos/MemphisBbq.jpeg")} alt="Memphis BBQ"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Details;
