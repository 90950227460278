import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../photos/logo.png'
import './Header.css';

function Header() {

    let [open,setOpen]=useState(false);

    return (
        <header className={`absolute top-0 left-0 w-full p-5 text-black flex justify-between md:justify-end items-center z-[100] `}>
            <Link to="/" className="header-logo md:hidden text-2xl md:text-5xl font-[Forum] tracking-wide">
                <img src={logo} alt="Logo" className="h-16" />
            </Link>
            <div onClick={()=>setOpen(!open)} className='text-3xl right-8 top-6 cursor-pointer md:hidden'>
                { !open &&
                    <MenuIcon/>
                }
                { open &&
                    <CloseIcon/>
                }
            </div>
            <div className={`header-nav md:justify-end font-[Forum] md:flex md:flex-wrap md:mr-8 md:items-center justify-center text-center
             md:pb-0 pb-4 md:static md:z-auto z-[-1] left-0 w-full md:w-auto pl-0 transition-all duration-500
              ease-in ${open ? 'overlay-active fixed top-0 left-0 w-full h-full bg-gray-400 opacity-ninety pt-24' : 'absolute top-[-490px]'}`}>
                <ul className="md:flex text-1.5xl">
                    {/*<ul className={`md:flex ${open ? 'text-3xl' : 'text-1.5xl'}`}>*/}
                    <li><Link to="/" onClick={() => setOpen(false)}>Home</Link></li>
                    <li><Link to="/details" onClick={() => setOpen(false)}>Details</Link></li>
                    <li><Link to="/story" onClick={() => setOpen(false)}>Our Story</Link></li>
                    <li><Link to="/registry" onClick={() => setOpen(false)}>Registry</Link></li>
                    <li><Link to="/rsvp" onClick={() => setOpen(false)}>RSVP</Link></li>
                </ul>
            </div>
            <Link to="/" className="header-logo hidden md:flex text-2xl md:text-5xl font-[Forum] tracking-wide">
                <img src={logo} alt="Logo" className="h-10 md:h-36 mr-8" />
            </Link>
        </header>
        // </div>
    );
}

export default Header;
