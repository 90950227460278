import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    getGuest,
    updateGuestResponse,
    updateGuestResponseWithKids,
    updateGuestResponseWithPlusOne,
    updateSpouseResponse
} from '../firebase';

function RSVP() {
    const navigate = useNavigate();
    const { guestId } = useParams();
    const [guestData, setGuestData] = useState(null);
    const [response, setResponse] = useState(null);
    const [plusOneResponse, setPlusOneResponse] = useState('');
    const [spouseResponse, setSpouseResponse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [childResponses, setChildResponses] = useState({});

    useEffect(() => {
        getGuest(guestId)
            .then((snapshot) => {
                if (snapshot.exists()) {
                    setGuestData(snapshot.val());
                } else {
                    console.log('Guest not found');
                }
            })
            .catch((error) => {
                console.error('Error fetching guest:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [guestId]);

    useEffect(() => {
        if (!loading && guestData && guestData.hasResponse) {
            if (guestData.rsvp) {
                navigate(`/rsvp/${guestId}/yes`);
            } else {
                navigate(`/rsvp/${guestId}/no`);
            }
        }
    }, [guestData, guestId, loading, navigate]);

    useEffect(() => {
        // Initialize childResponses based on kids data when guestData updates
        if (!loading && guestData.hasKids) {
            const initialChildResponses = {};
            for (const childId in guestData.kids) {
                initialChildResponses[childId] = '';
            }
            setChildResponses(initialChildResponses);
        }
    }, [guestData, loading]);

    const handleResponse = (response) => {
        setResponse(response);
    };

    const handleSpouseResponse = (response) => {
        setSpouseResponse(response);
    }

    const handlePlusOneResponse = (response) => {
        setPlusOneResponse(response);
    };

    const handleChildResponse = (childId, value) => {
        setChildResponses((prevState) => ({
            ...prevState,
            [childId]: value,
        }));
    };

    const handleSubmit = () => {
        console.log('Guest selected:', response);
        console.log(guestId);

        if (response === 'yes') {
            if (guestData.hasPlusOne && plusOneResponse === 'yes') {
                updateGuestResponseWithPlusOne(guestId, true, true)
            } else {
                updateGuestResponse(guestId, true);
            }
            if (guestData.hasKids) {
                const childResponsesBool = {};
                for (const childId in childResponses) {
                    childResponsesBool[childId] = childResponses[childId] === 'yes' ? true : false;
                }
                updateGuestResponseWithKids(guestId, childResponsesBool);
            }
            if (guestData.hasSpouse) {
                if (spouseResponse === 'yes') {
                    updateSpouseResponse(guestId, true);
                } else {
                    updateSpouseResponse(guestId, false);
                }
            }
            navigate(`/rsvp/${guestId}/yes`);
        } else if (response === 'no') {
            updateGuestResponse(guestId, false);
            navigate(`/rsvp/${guestId}/no`);
        } else {
            console.log('Please select a response before submitting.');
        }
    };

    if (loading) {
        return <div className='text-center mt-48 text-lg'>Loading...</div>;
    }

    return (
        <div className="mt-32 flex flex-col items-center justify-center md:mt-48">
            <h1 className="text-3xl font-semibold mb-4 md:mb-8">
                Welcome {guestData ? guestData.firstName : ''}!
            </h1>
            {guestData.hasSpouse ? (
                <>
                    <h2 className="text-xl mb-4 text-center mx-4">Will y'all be attending the ceremony on June 1st?</h2>
                    <div className="flex justify-between">
                        <button
                            className={`${
                                response === 'yes' && spouseResponse === 'yes'
                                    ? 'bg-green-500 text-white hover:bg-green-600'
                                    : 'border border-black'
                            } rounded-lg p-4 text-lg mx-2`}
                            onClick={() => {
                                handleResponse('yes');
                                handleSpouseResponse('yes');
                            }}
                        >
                            Yes, we'll be there!
                        </button>
                        <button
                            className={`${
                                response === 'yes' && spouseResponse === 'no'
                                    ? 'bg-yellow-300 text-white hover:bg-yellow-500'
                                    : 'border border-black'
                            } rounded-lg p-4 text-lg mx-2`}
                            onClick={() => {
                                handleResponse('yes');
                                handleSpouseResponse('no');
                            }}
                        >
                            Only one of us can make it!
                        </button>
                        <button
                            className={`${
                                response === 'no' && spouseResponse === 'no'
                                    ? 'bg-red-500 text-white hover:bg-red-600'
                                    : 'border border-black'
                            } rounded-lg p-4 text-lg mx-2`}
                            onClick={() => {
                                handleResponse('no');
                                handleSpouseResponse('no');
                            }}
                        >
                            No, we can't make it.
                        </button>
                    </div>
                </>
            ) :
                <>
                    <h2 className="text-xl mb-4 text-center mx-4">Will you be attending the ceremony on June 1st?</h2>
                    <div className="flex justify-between">
                        <button
                            className={`${
                                response === 'yes'
                                    ? 'bg-green-500 text-white hover:bg-green-600'
                                    : 'border border-black'
                            } rounded-lg p-4 text-lg mx-2`}
                            onClick={() => handleResponse('yes')}
                        >
                            Yes, I'll be there!
                        </button>
                        <button
                            className={`${
                                response === 'no'
                                    ? 'bg-red-500 text-white hover:bg-red-600'
                                    : 'border border-black'
                            } rounded-lg p-4 text-lg mx-2`}
                            onClick={() => handleResponse('no')}
                        >
                            No, I can't make it.
                        </button>
                    </div>
                </>
            }
            {guestData.hasPlusOne && (
                <div>
                    <h2 className="text-xl my-4 text-center mx-4">Will you be bringing a plus one?</h2>
                    <div className="flex justify-between">
                        <button
                            className={`${
                                plusOneResponse === 'yes'
                                    ? 'bg-green-500 text-white hover:bg-green-600'
                                    : 'border border-black'
                            } rounded-lg p-4 text-lg mx-2`}
                            onClick={() => handlePlusOneResponse('yes')}
                            disabled={response === "no"}
                        >
                            Yes, they'll be there!
                        </button>
                        <button
                            className={`${
                                plusOneResponse === 'no'
                                    ? 'bg-red-500 text-white hover:bg-red-600'
                                    : 'border border-black'
                            } rounded-lg p-4 text-lg mx-2`}
                            onClick={() => handlePlusOneResponse('no')}
                        >
                            No, they can't make it.
                        </button>
                    </div>
                </div>
            )}
            {guestData.hasKids && (
            <div>
                {Object.keys(guestData.kids).map((childId) => (
                    <div key={childId}>
                        <h2 className="text-xl my-4 text-center mx-4">
                            Will {childId} be there?
                        </h2>
                        <div className="flex justify-between">
                            <button
                                className={`${
                                    childResponses[childId] === 'yes'
                                        ? 'bg-green-500 text-white hover:bg-green-600'
                                        : 'border border-black'
                                } rounded-lg p-4 text-lg mx-2`}
                                onClick={() => handleChildResponse(childId, 'yes')}
                                disabled={response === "no"}
                            >
                                Yes, they'll be there!
                            </button>
                            <button
                                className={`${
                                    childResponses[childId] === 'no'
                                        ? 'bg-red-500 text-white hover:bg-red-600'
                                        : 'border border-black'
                                } rounded-lg p-4 text-lg mx-2`}
                                onClick={() => handleChildResponse(childId, 'no')}
                            >
                                No, they can't make it.
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        )}
            <button
                className="bg-blue-500 text-white hover:bg-blue-600 rounded-lg p-4 text-lg mt-8"
                onClick={handleSubmit}
            >
                Submit
            </button>
        </div>
    );
}

export default RSVP;
